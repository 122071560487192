import React, { useEffect, useState } from 'react';
import { FormControl } from 'components/Form';
import { Icon } from 'components/Icon/TextIcon';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import TextInput from 'components/TextInput/TextInput';
import { useDispatch } from 'react-redux';
import Notification from '../../../../components/Notification/Notification';
import styles from './SimphonyPOS.module.scss';
import {
  getPosTenderTypes,
  resetSimphonyOptions,
  updateSimphonyPosSettings,
} from 'locations/redux/LocationSettingsActions';
import { RootState, useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { ToggleEditWrapper } from 'components/ToggleEditWrapper/ToggleEditWrapper';
import { PosSystemType } from '@ready/dashboardv2api.contracts/dist/src/enums/PosSystemType';

export const SimphonyPOS = ({ isProcessing }: { isProcessing: boolean }) => {
  const { id: locationId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    apiUrl,
    apiPassword,
    apiUsername,
    clientId,
    host,
    posAmex,
    posDiscover,
    posJcb,
    posMastercard,
    posVisa,
    posInterac,
    posTenderType,
    posEmployee,
    posRevenueCenter,
    defaultBackupTenderType,
    backupLocationId,
    useBackupPayment,
    backupPOSProvider,
    posJcbBackup,
    posInteracBackup,
    posAmexBackup,
    posDiscoverBackup,
    posMastercardBackup,
    posVisaBackup,
    organization,
    location,
  } = useAppSelector((state: RootState) => state.location.posSettingsPanel.optionsSimphony2);
  const { tenderTypes } = useAppSelector((state: any) => state.location.posSettingsPanel.posOptions);
  const { isDirty } = useAppSelector(selectFormState);

  // We can assume that if a 'clientId' exists and 'apiPassword' is undefined, a config exists, and therefor a password exists.
  const passwordExists = !!clientId && apiPassword === undefined;
  const [isPasswordEditToggled, setIsPasswordEditToggled] = useState<boolean>(passwordExists);

  const dependentFieldsUnavailable = !apiUsername || !clientId || !host || !apiUrl;

  useEffect(() => {
    if (dependentFieldsUnavailable) {
      dispatch(resetSimphonyOptions());
    }
  }, [dependentFieldsUnavailable, dispatch]);

  useEffect(() => {
    dispatch(getPosTenderTypes(locationId));
  }, [dispatch, locationId]);

  // reset API Password on cancel changes button
  useEffect(() => {
    if (!isDirty) {
      setIsPasswordEditToggled(true);
    }
  }, [isDirty]);

  return (
    <>
      <FormControl label='Client ID *'>
        <TextInput
          placeholder='Enter Client ID'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ clientId: event.target.value.toString() }));
          }}
          value={clientId}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Host *'>
        <TextInput
          placeholder='Enter Host'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ host: event.target.value.toString() }));
          }}
          value={host}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='API URL *'>
        <TextInput
          placeholder='Enter API URL'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ apiUrl: event.target.value.toString() }));
          }}
          value={apiUrl}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='API Username *'>
        <TextInput
          placeholder='Enter API Username'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ apiUsername: event.target.value.toString() }));
          }}
          value={apiUsername}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='API Password *'>
        {isPasswordEditToggled ? (
          <ToggleEditWrapper
            readonlyValue='*******'
            editButtonText='Edit Password'
            children={<></>}
            onEdit={() => setIsPasswordEditToggled(false)}
          />
        ) : (
          <TextInput
            placeholder='Enter API Password'
            onChange={(event) => {
              if (event.target.value) {
                dispatch(updateSimphonyPosSettings({ apiPassword: event.target.value.toString() }));
              } else {
                dispatch(updateSimphonyPosSettings({ apiPassword: undefined }));
              }
            }}
            value={apiPassword ?? ''}
            loading={isProcessing}
          />
        )}
      </FormControl>

      {dependentFieldsUnavailable && (
        <Notification type='warning' className={styles.notification}>
          <p>
            <i className={Icon.InfoCircle} />
            <span>The fields above must be completed before the additional settings below can be configured.</span>
          </p>
        </Notification>
      )}
      <FormControl label='Organization ID *'>
        <TextInput
          placeholder='Enter Organization ID *'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ organization: event.target.value }));
          }}
          value={organization}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Location ID *'>
        <TextInput
          placeholder='Enter Location ID *'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ location: event.target.value }));
          }}
          value={location}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Default Employee ID *'>
        <TextInput
          placeholder='Enter employee ID'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ posEmployee: event.target.value.toString() }));
          }}
          value={posEmployee}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Revenue Center ID'>
        <TextInput
          placeholder='Enter Revenue Center ID'
          onChange={(event) => {
            const value = Number(event.target.value);
            dispatch(updateSimphonyPosSettings({ posRevenueCenter: isNaN(value) ? 0 : value }));
          }}
          value={posRevenueCenter}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Default Tender Types *'>
        <SelectFilter
          placeholder='Select a tender type '
          populating={tenderTypes.loading}
          disabled={dependentFieldsUnavailable}
          loading={isProcessing}
          options={tenderTypes.results}
          value={mapSelectOption(tenderTypes.results, posTenderType) || ''}
          isSearchable
          isClearable
          onChange={(data) => {
            dispatch(updateSimphonyPosSettings({ posTenderType: data ? data.value.toString() : '' }));
          }}
        />
      </FormControl>

      <FormControl label='Card Specific Tender Types'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Mastercard</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posMastercard) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posMastercard: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Visa</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posVisa) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posVisa: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Amex</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posAmex) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posAmex: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Discover</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posDiscover) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posDiscover: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>JCB</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posJcb) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posJcb: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Interac</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posInterac) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posInterac: data ? data.value.toString() : '' }));
            }}
          />
        </div>
      </FormControl>

      <FormControl label='Backup Payment Settings'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Use Backup Payment</span>
          <SelectFilter
            placeholder='Select option'
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ]}
            value={useBackupPayment ? { label: 'Yes', value: 'true' } : { label: 'No', value: 'false' }}
            isSearchable={false}
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ useBackupPayment: data?.value === 'true' }));
            }}
          />
        </div>
        
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Backup POS Provider</span>
          <SelectFilter
            placeholder='Select backup POS provider'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            options={[
              { label: 'Ready POS', value: PosSystemType.readypos },
              { label: 'Omnivore', value: PosSystemType.omnivore },
              { label: 'Squirrel', value: PosSystemType.squirrel },
              { label: 'Par Brink', value: PosSystemType.parbrink },
              { label: 'Bypass 2', value: PosSystemType.bypass2 },
              { label: 'Square', value: PosSystemType.square },
              { label: 'NCR Aloha', value: PosSystemType.ncraloha },
              { label: 'Micros 3700', value: PosSystemType.micros3700 },
              { label: 'POSitouch', value: PosSystemType.positouch },
              { label: 'Simphony 2', value: PosSystemType.simphony2 },
              { label: 'Basic POS', value: PosSystemType.basicpos },
              { label: 'Micros Simphony', value: PosSystemType.microssimphony }
            ]}
            value={backupPOSProvider ? { label: backupPOSProvider.charAt(0).toUpperCase() + backupPOSProvider.slice(1), value: backupPOSProvider } : null}
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ backupPOSProvider: data ? data.value as PosSystemType : undefined }));
            }}
          />
        </div>
        
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Backup Location ID</span>
          <TextInput
            placeholder='Enter Backup Location ID'
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ backupLocationId: event.target.value.toString() }));
            }}
            value={backupLocationId}
            loading={isProcessing}
            disabled={dependentFieldsUnavailable || !useBackupPayment}
          />
        </div>
        
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Default Backup Tender Type</span>
          <TextInput
            placeholder='Enter backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={defaultBackupTenderType || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ defaultBackupTenderType: event.target.value }));
            }}
          />
        </div>
      </FormControl>

      <FormControl label='Backup Card Specific Tender Types'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Mastercard Backup</span>
          <TextInput
            placeholder='Enter Mastercard backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posMastercardBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posMastercardBackup: event.target.value }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Visa Backup</span>
          <TextInput
            placeholder='Enter Visa backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posVisaBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posVisaBackup: event.target.value }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Amex Backup</span>
          <TextInput
            placeholder='Enter Amex backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posAmexBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posAmexBackup: event.target.value }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Discover Backup</span>
          <TextInput
            placeholder='Enter Discover backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posDiscoverBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posDiscoverBackup: event.target.value }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>JCB Backup</span>
          <TextInput
            placeholder='Enter JCB backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posJcbBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posJcbBackup: event.target.value }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Interac Backup</span>
          <TextInput
            placeholder='Enter Interac backup tender type'
            disabled={dependentFieldsUnavailable || !useBackupPayment}
            loading={isProcessing}
            value={posInteracBackup || ''}
            onChange={(event) => {
              dispatch(updateSimphonyPosSettings({ posInteracBackup: event.target.value }));
            }}
          />
        </div>
      </FormControl>
    </>
  );
};
