import {
  ILocationPosOmnivoreSettings,
  ILocationPosBrinkSettings,
  ILocationPosBypass2Settings,
  ILocationPosSquareSettings,
  ILocationPosSimphony2SettingsEdit,
} from '@ready/dashboardv2api.contracts';

import { Option as SelectFilterOption } from '../../components/SelectFilter/SelectFilter';

// same as ILocationPosSettings but not optional
export interface IPosSettingsPanel {
  posIntegration: string;
  ignoreInStockFromPosItem: boolean;
  optionsBrink: ILocationPosBrinkSettings;
  optionsOmnivore: ILocationPosOmnivoreSettings;
  optionsBypass2: ILocationPosBypass2Settings;
  optionsSquare: ILocationPosSquareSettings;
  optionsSimphony2: ILocationPosSimphony2SettingsEdit;
  posOptions: IPosSelectOptions;
  validation: IPosSettingsValidation;
  locations: SelectFilterOption[];
  loadingLocations: boolean;
}

export interface IPosSelectOptions {
  employees: {
    loading: boolean;
    results: SelectFilterOption[];
    selection: SelectFilterOption | undefined;
  };
  revenueCenters: {
    loaded: boolean;
    loading: boolean;
    results: SelectFilterOption[];
  };
  tenderTypes: {
    loaded: boolean;
    loading: boolean;
    results: SelectFilterOption[];
  };
}

export interface IPosSettingsValidation {
  posSettings: {
    posIntegration: {
      hasError: boolean;
      error: string;
    };
  };
}

export interface IPosIntegrationOptions {
  posIntegrationList: SelectFilterOption[];
}

export const defaultSimphonyConfigs: ILocationPosSimphony2SettingsEdit = {
  posAmex: '',
  posDiscover: '',
  posJcb: '',
  posInterac: '',
  posMastercard: '',
  posVisa: '',
  posEmployee: '',
  posTenderType: '',
  clientId: '',
  host: '',
  apiUrl: '',
  apiPassword: undefined,
  apiUsername: '',
  organization: '',
  location: '',
  posRevenueCenter: 0,
  posMastercardBackup: '',
  posVisaBackup: '',
  posAmexBackup: '',
  posDiscoverBackup: '',
  posJcbBackup: '',
  posInteracBackup: '',
  backupPOSProvider: undefined,
  backupLocationId: '',
  useBackupPayment: false,
  defaultBackupTenderType: '',
  serviceChargeEnabled: false,
  serviceChargeType: undefined,
  serviceChargeAmount: undefined,
  serviceChargeId: '',
  serviceChargePreTax: undefined,
  serviceChargeTaxRate: undefined,
};

export const posSettingsPanel: IPosSettingsPanel = {
  posIntegration: '',
  ignoreInStockFromPosItem: false,
  optionsBrink: {
    brinkLocationId: '',
    apiUrl: '',
    posAmex: '',
    posDiscover: '',
    posEmployee: '',
    posJcb: '',
    posInterac: '',
    posMastercard: '',
    posOrderType: '',
    posRevenueCenter: '',
    posTenderType: '',
    posVisa: '',
  },
  optionsOmnivore: {
    omnivoreLocationId: '',
    omnivorePosSettingSupportTable: false,
    omnivorePosSettingsDisableUserInfo: false,
    omnivorePosSettingsModifierGroup: false,
    omnivorePosSettingsRequiresCovers: false,
    omnivorePosSettingsSendAndComment: false,
    omnivorePosType: '',
    posAmex: '',
    posDiscover: '',
    posEmployee: '',
    posJcb: '',
    posInterac: '',
    posMastercard: '',
    posVisa: '',
    posOrderType: '',
    posRevenueCenter: '',
    posPriceCheckTable: '',
    posPriceCheckRevenueCenter: '',
    posTenderType: '',
  },
  optionsBypass2: {
    locationId: '',
  },
  optionsSquare: {
    squareLocationId: '',
    squareApiKey: '',
    squareOrderEmployeeId: '',
    squareOrderRevenueCenter: '',
    squareOrderType: '',
    squareOrderTenderType: '',
    squarePriceCheckTableId: '',
    squarePriceCheckRevenueCenterId: '',
    squareSupportsTables: false,
    squareRequiresCovers: false,
    squareRequiresModifierGroupPriceChecks: false,
    squareOrderDisableUserInfo: false,
    squareSeparateItemAndComment: false,
  },
  optionsSimphony2: defaultSimphonyConfigs,

  posOptions: {
    employees: {
      loading: false,
      results: [],
      selection: undefined,
    },
    revenueCenters: {
      loaded: false,
      loading: false,
      results: [],
    },
    tenderTypes: {
      loaded: false,
      loading: false,
      results: [],
    },
  },
  validation: {
    posSettings: {
      posIntegration: {
        hasError: false,
        error: '',
      },
    },
  },
  locations: [],
  loadingLocations: false,
} as IPosSettingsPanel;

export const getPOSSettingsValidation = (): IPosSettingsValidation => {
  return {
    posSettings: {
      posIntegration: {
        hasError: false,
        error: '',
      },
    },
  };
};
